import React from "react";
import { graphql, PageProps } from "gatsby";

import { Hero, PostList } from "components/ACF";
import { Layout } from "components";
import { ProjectPageByCategoryQuery } from "codegen";

interface JobLocationArchivePageContext {
  currentPageNumber: number;
  numberOfPages: number;
}

const JobLocationArchiveTemplate = ({
  pageContext,
  data,
  location,
}: PageProps<ProjectPageByCategoryQuery, JobLocationArchivePageContext>): JSX.Element => {
  const { currentPageNumber, numberOfPages } = pageContext;
  const { post, posts, categories, locations } = data;
  const { seo, name } = post;
  const allCategories = categories.nodes.map((node) => node);
  const allLocations = locations.nodes.map((node) => node);

  return (
    <Layout className="job-template" seo={seo}>
      <div className="category-archive__wrapper wrapper">
        <Hero
          data={{
            content: `<h1>${name}</h1>`,
            compact: true,
            breadcrumbs: "none",
            image: post?.featuredImage?.image,
          }}
          location={seo.breadcrumbs}
        />
        <PostList
          cptItems={posts.nodes}
          cptCategories={allCategories}
          cptLocations={allLocations}
          browserLocation={location}
          data={{
            currentPageNumber: currentPageNumber,
            numberOfPages: numberOfPages,
            postType: "job",
          }}
        />
      </div>
    </Layout>
  );
};

export default JobLocationArchiveTemplate;

export const JobLocationArchivePageQuery = graphql`
  query ProjectPageByLocation($id: String!, $offset: Int!, $postsPerPage: Int!) {
    post: wpJobLocation(id: { eq: $id }) {
      id
      slug
      uri
      name
      ...SEOJobLocationQuery
    }
    categories: allWpJobCategory {
      nodes {
        id
        name
        slug
        uri
        jobs {
          nodes {
            id
          }
        }
      }
    }
    locations: allWpJobLocation {
      nodes {
        id
        name
        slug
        uri
        jobs {
          nodes {
            id
          }
        }
      }
    }
    posts: allWpJob(
      sort: { fields: [date], order: DESC }
      filter: { jobLocations: { nodes: { elemMatch: { id: { eq: $id } } } } }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        ...JobFields
      }
    }
  }
`;
